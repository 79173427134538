/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined } from "@ant-design/icons";
import { Col, message, notification, Progress, Row } from "antd";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import JsZip from "jszip";
import pLimit from "p-limit";
import React from "react";

interface FileDownload {
  name: string;
  url: string;
}

type UseDownloadResult = [
  (files: FileDownload[], name?: string) => void,
  { downloading: boolean }
];

interface ISum {
  name: string;
  percent: number;
  uniq: string;
  fileName: string;
}

let sum: ISum[] = [];
let isClose = false;

const useZipDownload2 = (): UseDownloadResult => {
  const MyProgress = ({
    isPercent,
    name,
  }: {
    isPercent: number;
    name: string;
  }) => {
    return (
      <div style={{ position: "relative" }}>
        <div>{name}</div>
        <Progress
          percent={isPercent}
          status="active"
          strokeColor={{ from: "#FF748E", to: "#FFB6C1" }}
        />
      </div>
    );
  };

  const updateCallback = (
    percent: number,
    currentFile: string,
    uniq: string,
    fileName: string
  ) => {
    const find = sum?.find((f: ISum) => f?.uniq === uniq);
    if (find === undefined) {
      sum.push({
        name: currentFile,
        percent: percent,
        uniq: uniq,
        fileName: fileName,
      });
    } else {
      sum = sum?.map((m) => {
        return m?.uniq === find?.uniq ? { ...m, percent: percent } : m;
      });
    }

    setTimeout(function () {
      const reCheck = sum?.filter((fc) => fc?.percent === 100);
      if (reCheck.length === sum?.length) {
        // setTimeout(function () {
        //   sum = [];
        //   return notification.destroy();
        // }, 1000);
        isClose = true;
      }

      return notification.open({
        message: (
          <Row justify="space-between">
            <div>กำลังดาวน์โหลดไฟล์</div>
            <Col>
              <CloseOutlined
                style={{
                  position: "absolute",
                  right: -20,
                  top: 5,
                  fontSize: 13,
                  color: "#6e6d6d",
                  display: !isClose ? "none" : "block",
                }}
                onClick={() => {
                  if (!isClose) {
                    message.warning(
                      "การดาวน์โหลดยังไม่เสร็จสิ้น กรุณารอสักครู่!"
                    );
                  } else {
                    sum = [];
                    isClose = false;
                  }
                  return notification.destroy();
                }}
              />
            </Col>
          </Row>
        ),
        description: sum?.map(
          (s, idx: number) =>
            (
              <MyProgress
                isPercent={Number(s.percent.toFixed(2))}
                name={`${idx + 1}. ` + s.fileName}
                key={idx}
              />
            ) || []
        ),
        duration: 0,
        key: "nocticProgress",
        closeIcon: <div style={{ display: "none" }} />,
      });
    }, 200);
  };

  const limit = pLimit(2);
  const handleDownload = React.useCallback(
    async (files: FileDownload[], name?: string) => {
      message.warning("กำลังเตรียมไฟล์ดาวน์โหลด กรุณารอสักครู่!");

      const downloadPromises = async (fd: any) => {
        return await fetch(fd.url).then((res) => ({
          name: fd.url
            .substring(fd.url.lastIndexOf("/") + 1, fd.url.length)
            ?.split("?")[0],
          blob: res.blob(),
        }));
      };

      try {
        const downLoadGroup = await Promise.all(
          files.map((f: any) => limit(async () => downloadPromises(f)))
        );
        const zip = JsZip();
        downLoadGroup.forEach((item: any) => {
          zip.file(item.name, item.blob, { binary: true });
        });
        const currentDate = new Date().getTime();
        const fileName = `${name ? name : "filedicom"}-${dayjs(
          Number(currentDate)
        ).format("DDMMYYYYHHmmss.zip")}`;
        const uniq = "id" + new Date().getTime();
        await zip
          .generateAsync(
            {
              type: "blob",
              streamFiles: true,
            },
            (e) => {
              updateCallback(e?.percent, e.currentFile, uniq, fileName);
            }
          )
          .then((content) => {
            FileSaver.saveAs(content, fileName);
          })
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  return [handleDownload, { downloading: false }];
};
export default useZipDownload2;
