/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Col, Form, FormInstance, Input, Radio, Row } from "antd";
import { useLayoutEffect, useState } from "react";
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormInstance<any>;
  values: any;
  disabled?: boolean;
  isFormList?: string | number;
}

const Choice = ({ values, disabled, isFormList, ...props }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isValues, setIsValues] = useState<any>();

  const html = values["description"] || ``;

  useLayoutEffect(() => {
    const isValue = props.form?.getFieldValue(values["keyName"]);
    if (typeof isValue === "boolean") {
      if (!!isValue) {
        setIsVisible(true);
      }
    }
    setIsValues(isValue);
  }, []);

  const setRadio = (value: any) => {
    setIsValues(value);
    if (value === "Screening" || value === "Diagnostic") {
      props.form?.setFieldsValue({
        diagnosisFormGroup1_question1_diagnostic: "",
      });
    } else if (typeof value === "number")
      props.form?.setFieldsValue({
        diagnosisFormGroup7_question1_number4_follow: "",
        diagnosisFormGroup7_question1_number4_mounth: "",
        diagnosisFormGroup7_question1_number3: "",
        diagnosisFormGroup7_question1_number5: "",
      });
  };

  return (
    <div>
      <Form.Item
        label={values["title"] || "เลือกคำตอบ"}
        name={isFormList ? [isFormList, values["keyName"]] : values["keyName"]}
        rules={[
          {
            required: !!values.required,
            message: `กรุณาใส่ข้อมูล ${values["title"]}`,
          },
        ]}
      >
        <Radio.Group disabled={disabled}>
          {/* <Space direction={"vertical"}> */}
          <Row gutter={[16, 1]}>
            {values.option?.map((item: any, ind: number) => {
              if (
                item?.children?.["keyName"] &&
                item.value &&
                isValues &&
                item.value !== isValues
              ) {
                props.form?.setFieldsValue({
                  [item.children["keyName"]]: "",
                });
                if (item?.children2?.["keyName"]) {
                  props.form?.setFieldsValue({
                    [item.children2["keyName"]]: "",
                  });
                }
              }
              return (
                <Col
                  md={24}
                  lg={item.title === "Diagnostic" ? 24 : 12}
                  style={{ margin: "6px 0px" }}
                  key={ind}
                >
                  <Radio
                    value={item.value}
                    className="content-radio"
                    onClick={() => {
                      typeof item.value === "boolean" &&
                        setIsVisible(item.value);
                    }}
                    onChange={() => setRadio(item.value)}
                  >
                    {/* {!isValues &&
                  props.form?.getFieldValue(values["keyName"]) === item.value &&
                  setIsValues(item.value)} */}
                    <Row gutter={[16, 16]} align="middle">
                      <Col
                        md={
                          item.children?.keyName ===
                          "diagnosisFormGroup7_question1_number5"
                            ? 0
                            : ""
                        }
                      >
                        {item.title}
                      </Col>

                      {!!item.children ? (
                        isValues === item.value && item?.children?.option ? (
                          <Form.Item
                            name={item.children["keyName"]}
                            valuePropName="value"
                          >
                            <Checkbox.Group>
                              {item?.children?.option?.map(
                                (item2: any, ind2: number) => {
                                  return (
                                    <Col key={ind2}>
                                      <Checkbox value={item2?.value}>
                                        {item2?.title}
                                      </Checkbox>
                                    </Col>
                                  );
                                }
                              )}
                            </Checkbox.Group>
                          </Form.Item>
                        ) : (
                          !item?.children?.option && (
                            <Col>
                              <Form.Item
                                name={item.children["keyName"]}
                                className="radio-doctor  m-0"
                              >
                                <Input
                                  style={{ width: item.children["width"] }}
                                  disabled={
                                    !!item.value && item.value === isValues
                                      ? false
                                      : true
                                  }
                                />
                              </Form.Item>
                            </Col>
                          )
                        )
                      ) : null}

                      {!!item.title2 ? <Col>{item.title2}</Col> : null}
                      {!!item.children2 ? (
                        <Col>
                          <Form.Item
                            name={item.children2["keyName"]}
                            className="radio-doctor m-0"
                          >
                            <Input
                              style={{ width: item.children2["width"] }}
                              disabled={
                                !!item.value && item.value === isValues
                                  ? false
                                  : true
                              }
                            />
                          </Form.Item>
                        </Col>
                      ) : null}
                    </Row>
                  </Radio>
                </Col>
              );
            })}
          </Row>
          {/* </Space> */}
        </Radio.Group>
      </Form.Item>
      {!!values["description"] && (
        <span dangerouslySetInnerHTML={{ __html: html }} />
      )}
      {isVisible && props.children}
    </div>
  );
};

export default Choice;
