import type { ITypeQuestionProp } from "@Interface/patient";
import type { FormInstance } from "antd";

import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import CustomCard from "@Components/CCard/CustomCard";
import ViewFileInfo from "@Components/CFilesInfo/ViewFileInfo";
import CreateFormItem from "@Components/form/CreateFormItem/_RenderFormItem";
import { VisitField, VisitFiles } from "@Interface/visit";
import _VisitService, { visitResultDetection } from "@Services/api/visit";
import { _RES_validateError } from "@Utils/validate";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";
import { SnippetsFilled, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalReport3D, { IResultReport3D } from "./ModalReport3D";

interface Props {
  dataForm?: FormInstance<any> | any;
  questionsForm?: ITypeQuestionProp | null;
  fileList?: VisitFiles[];
  studyId?: string | null;
  visitInfo?: VisitField | null;
  reportFileList?: VisitFiles[];
}

const ABUS = ({
  questionsForm,
  dataForm,
  fileList,
  studyId,
  visitInfo,
  reportFileList,
}: Props) => {
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const params = useParams() as { visitId: string };
  const [downloadAndZip] = useZipDownload2();
  const [visibleViewReport, setVisibleViewReport] = useState(false);

  const [report3D, setReport3D] = useState<IResultReport3D[]>([]);

  const handleDownload = async () => {
    const id = Number(params.visitId);
    const res = await _VisitService.visitDownloadFiles(
      id,
      token.accessToken,
      "A_BUS"
    );
    const data = res.result;
    downloadAndZip(
      data,
      `${visitInfo?.patient?.fullName?.replace(/ /g, "-")}-A_BUS`
    );
  };

  const onOk = () => {
    // setVisibleViewReport(false)
  };
  const onCancel = () => {
    setVisibleViewReport(false);
  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    const keyOnLoad = "getVisitResultDetection";
    const res = await visitResultDetection(params.visitId, token.accessToken);
    _RES_validateError(res, keyOnLoad, {
      Code_401: { runOption: _signOut },
      Code_200: {
        runOption: () => {
          setReport3D(res?.result);
          // console.log("res", res);
          return true;
        },
      },
    });
  };

  const geStatus = (status: string) => {
    switch (status) {
      case "รอประมวลผล AI":
        return { status: "processing", name: "รอประมวลผล AI" };
      case "ยังไม่ประมวลผล AI":
        return { status: "warning", name: "ยังไม่ประมวลผล AI" };
      case "ประมวลผล AI เสร็จสิ้น":
        return { status: "success", name: "ประมวณผล AI เสร็จสิ้น" };
      default:
        return { status: "warning", name: "ยังไม่ประมวลผล AI" };
    }
  };

  // UNPROCESSED: 'ยังไม่ประมวลผล AI',
  // WAITING: 'รอประมวลผล AI',
  // SUCCESS: 'ประมวลผล AI เสร็จสิ้น',

  return (
    <Row className="container-box tabs-content" gutter={[10, 10]}>
      <Col span={24}>
        <ViewFileInfo
          title="ข้อมูลภาพ ABUS"
          // onDownload={handleDownload}
          // onViewDICOM={!!studyId ? handleViewDICOM : undefined}
          extraAction={[
            <Button
              icon={<VerticalAlignBottomOutlined />}
              size="middle"
              onClick={handleDownload}
              disabled={false}
            />,
            <Button
              type="primary"
              icon={<SnippetsFilled />}
              size="middle"
              onClick={() => setVisibleViewReport(true)}
            >
              Report 3D ABUS
            </Button>,
            // <Button icon={<EyeOutlined />} size="middle" onClick={handleViewDICOM}>
            //   DICOM VIEWER
            // </Button>,
          ]}
          status={[geStatus(visitInfo?.statusAi || "")]}
          fileList={fileList || []}
        />
      </Col>
      <Col span={24}>
        <CustomCard title="ABUS Form">
          <Tabs type="card" size="middle" style={{ background: "#fff" }}>
            {!!questionsForm?.abus &&
              questionsForm?.abus?.map((item: any, ind: number) => (
                <Tabs.TabPane
                  tab={
                    <span style={{ textTransform: "capitalize" }}>
                      {item.name}
                    </span>
                  }
                  key={item.name}
                  style={{ background: "#fff" }}
                >
                  <div className="box">
                    <CreateFormItem
                      key={ind}
                      values={item.questions}
                      form={dataForm}
                      visitInfo={visitInfo}
                      fileList={fileList}
                    />
                  </div>
                </Tabs.TabPane>
                // <CreateFormItem
                //   key={ind}
                //   values={item.questions}
                //   form={dataForm}
                //   visitInfo={visitInfo}
                //   fileList={fileList}
                // />
              ))}
          </Tabs>
        </CustomCard>
      </Col>

      <ModalReport3D
        visible={visibleViewReport}
        onOk={onOk}
        onCancel={onCancel}
        values={report3D}
      />
    </Row>
  );
};

export default ABUS;
